<template>
  <b-form @submit="onSubmit" v-if="show">
    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">TC Kimlik No</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="form.ssn" type="number" required />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Adı</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="form.name" type="text" required />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Soyadı</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="form.surname" type="text" required />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Cinsiyeti</label>
      </b-col>
      <b-col sm="8">
        <b-form-select v-model="form.gender" :options="genderOptions" required />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Doğum Tarihi</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="form.birthDate" type="date" required />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Yaşanılan İl</label>
      </b-col>
      <b-col sm="8">
        <b-form-select v-model="form.contact.cityWhereLocated" :options="cities" required>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">E-Posta</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="form.contact.email" type="email" />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Telefon</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="form.contact.gsm" type="number" />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Şifre</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="form.system.password" type="password" required />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Yetki</label>
      </b-col>
      <b-col sm="8">
        <b-form-select v-model="selectedOption" :options="options"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Kulüp</label>
      </b-col>
      <b-col sm="8">
        <b-form-select v-model="selectedClub" :options="clubs" text-field="name" value-field="id"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="spacing">
      <b-col sm="10" class="labelContainer">
        <b>
          <label class="spacing"><a target="_blank" href="https://kano.gov.tr/kvkk-aydinlatma-metni">KVKK Aydınlatma
              metnini</a>
            okudum onayladım.</label></b>
      </b-col>
      <b-col sm="2" class="my-auto">
        <b-form-checkbox v-model="form.kvkk" type="checkbox" required />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <VueRecaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" size="invisible"
        sitekey="6Ldz3QgfAAAAAAy-TksDqe7AaViHD9AUrh7R0-A5" />
    </b-row>

    <b-form-row class="center spacing">
      <b-col>
        <b-button type="reset" variant="danger">İptal</b-button>
      </b-col>
      <b-col>
        <b-button type="submit" variant="success">Gönder</b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";
import cities from "../../store/constants/cities";
import { genderOptions } from "@/store/constants/personel.js";
import options from "../../store/constants/roleOptions";
import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "RegisterPage",
  components: { VueRecaptcha },
  data() {
    return {
      form: {
        kvkk: false,
        ssn: "",
        name: "",
        surname: "",
        birthDate: "",
        gender: "",
        contact: {
          cityWhereLocated: "",
        },
        branch: {},
        education: {},
        system: {
          password: "",
        },
      },
      cities: cities,
      show: true,
      selectedOption: "",
      selectedClub: 0,
      genderOptions,
      options: options,
    };
  },
  computed: {
    ...mapGetters(["getClubNames"]),
    clubs: {
      get() {
        return [{ name: "Yok", id: 0 }].concat(this.getClubNames);
      },
    },
  },
  created() {
    this.$store.dispatch("getClubNames");
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.form.kvkk == false) {
        this.$store.dispatch("notification/setNotifications", [
          {
            title: "Hata!",
            body: "Lütfen KVKK Aydınlatma metnini okuyup onaylayın.",
            type: "danger",
          },
        ]);
        return;
      }

      this.$refs.recaptcha.execute();
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.form.recaptchaToken = recaptchaToken;
      this.$refs.recaptcha.reset();
      this.form.branch[this.selectedOption] = "active";
      this.form.branch.clubId = this.selectedClub;
      this.$store.dispatch("registerPersonel", this.form);
      setTimeout(() => this.$router.push("/login"), 2000);
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>


<style scoped>
html,
body {
  background-color: black;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.labelContainer {
  display: grid;
  place-items: center;
}

.buttonBar {
  text-align: end;
}

.center {
  text-align: center;
}

.spacing {
  margin-top: 0.5rem;
}
</style>